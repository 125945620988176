<template>
  <section class="full-height">
    <div class="columns is-marginless">
      <div class="column is-12">    
          <div class="columns is-gapless is-marginless is-centered is-mobile">
            <div class="column is-narrow is-mobile">
              <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >InterShiftImport</div>
            </div>
          </div>
      </div>
    </div>
    
    <div class="columns is-mobile is-gapless is-multiline m2rem">
      <div class="column is-8"> 
      <b-table
          :data="userDataFiltered"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :mobile-cards="true">

          <b-table-column field="import_intershift_schedule_users_id" label="delete" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered>
              <b-button 
                v-if="props.row.totalShifts <=0"
                @click="deleteUser(props.row)" type="is-light">
                delete
              </b-button>
          </b-table-column>
          <b-table-column field="import_intershift_schedule_users_id" label="id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.import_intershift_schedule_users_id }}
          </b-table-column>
          <b-table-column field="import_intershift_schedule_users_user_id" label="user id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.import_intershift_schedule_users_user_id }}
          </b-table-column>
          <b-table-column field="import_intershift_schedule_users_company_user_id" label="company user id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.import_intershift_schedule_users_company_user_id }}
          </b-table-column>
          <b-table-column field="import_intershift_schedule_users_name" label="name" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
              {{ props.row.import_intershift_schedule_users_name }}
          </b-table-column>
          <b-table-column field="import_intershift_schedule_users_big_number" label="big" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
              {{ props.row.import_intershift_schedule_users_big_number }}
          </b-table-column>
          <b-table-column field="email" label="email" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.email }}
          </b-table-column>
          <b-table-column field="import_intershift_schedule_users_name_refkey" label="refKey" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
              {{ props.row.import_intershift_schedule_users_name_refkey }}
          </b-table-column>
          <b-table-column field="totalShifts" label="shifts count" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered>
              {{ props.row.totalShifts }}
          </b-table-column>
          <b-table-column label="actions" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" centered>
              <b-button @click="selectUser(props.row)" :type="selectedUser && selectedUser.import_intershift_schedule_users_id === props.row.import_intershift_schedule_users_id ? 'is-primary' : 'is-light'">Select</b-button>
          </b-table-column>
          

          <template #empty>
              <div class="has-text-centered">No records</div>
          </template>

      </b-table>
      </div>
    </div>
    
    <div class="columns is-mobile is-gapless is-multiline m2rem">
      <div class="column is-6"> 
      <b-table
          :data="shiftDataFiltered"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :mobile-cards="true">

          <b-table-column field="import_intershift_schedule_user_id" label="id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.import_intershift_schedule_user_id }}
          </b-table-column>
          <b-table-column field="user_id" label="user id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.user_id }}
          </b-table-column>
          <b-table-column field="company_user_id" label="company user id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.company_user_id }}
          </b-table-column>
          <b-table-column field="name_refkey" label="refkey" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
              {{ props.row.name_refkey }}
          </b-table-column>
          <b-table-column field="total" label="total" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
              {{ props.row.total }}
          </b-table-column>
          
          <b-table-column label="actions" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" centered>
              <b-button v-if="selectedUser && props.row.import_intershift_schedule_user_id === null && props.row.user_id === null && props.row.company_user_id === null" @click="mapShifts(props.row)" type="is-primary">Map</b-button>
          </b-table-column>
          
         
          <template #empty>
              <div class="has-text-centered">No records</div>
          </template>

      </b-table>
      </div>
    </div>
    
       
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
// import { API_ROOT } from '@/config/app.js';

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { API_ROOT, WEB_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

export default {
  name: "EmptyPage", 
  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      companyId           : null,
      
      userData            : [],
      userDataFiltered    : [],
      shiftData           : [],
      shiftDataFiltered   : [],
      tableIsLoading      : false,
      WEB_ROOT: WEB_ROOT,
      API_ROOT: API_ROOT,
      
      
      selectedUser        : null,
      selectedShiftRefKey : null,
     
    }
  },
  
  async mounted(){
    this.companyId = this.$route.params.companyId;
    this.ShowPageLoading(15000)
    this.getData()
  },
  
  methods: {
    selectUser(props){
      if (props === this.selectedUser) {
        this.selectedUser = null
      } else {
        this.selectedUser = props  
      }
      
      this.filterUserData()
    },
    async deleteUser(props) {
      // console.info(`props:`, props)      
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/intershift/company/${this.companyId}/intershiftData/deleteUser`, {
        intershiftUserId: props.import_intershift_schedule_users_id
      });
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to map data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.getData()      
    },
    async mapShifts(props){
      if (!this.selectedUser) return
      console.info(`mapShifts:`, props)
      
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/intershift/company/${this.companyId}/intershiftData`, {
        intershiftUserId: this.selectedUser.import_intershift_schedule_users_id,
        nameRefkey: props.name_refkey
      });
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to map data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.getData()
    },
    filterUserData(){
      let data = []
      for(let user of this.userData) {
        // console.info(`user:`, user)
        if (this.selectedUser !== null && user.import_intershift_schedule_users_id !== this.selectedUser.import_intershift_schedule_users_id) {
          continue
        }
        data.push(user)
      }
      this.userDataFiltered = data
    },
    filterShiftData(){
      let data = []
      for(let shift of this.shiftData) {
        // console.info(`shift:`, shift)
        if (this.selectedShiftRefKey !== null && shift.name_refkey !== this.selectedShiftRefKey) {
          continue
        }
        data.push(shift)
      }
      this.shiftDataFiltered = data
      // console.info(`this.shiftDataFiltered:`, this.shiftDataFiltered)
    },
    async getData() {
      let response = await axios_api.get(`${API_ROOT}/intershift/company/${this.companyId}/intershiftData`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.userData  = response.data.payload.users
      // console.info(`this.userData:`, this.userData)
      this.filterUserData()
      
      this.shiftData  = response.data.payload.shifts
      // console.info(`this.shiftData:`, this.shiftData)
      this.filterShiftData()
       
      this.HidePageLoading()
    },
   
    
    /* eslint-disable-next-line */        
    dateThAttrs(column) {
      return null
    },
            
    /* eslint-disable-next-line */
    columnTdAttrs(row, column) {
      return null
    },
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    
  },
};
</script>

<style>
    

</style>